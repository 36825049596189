<template>
  <!-- table -->
  <div class="ecommerce-application">
    <product-detail :products-all="selectedRowProduct" />
    <vue-good-table
      :columns="columns"
      :rows="orders"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'created_at'">
          {{ new Date(props.row.created_at).toLocaleDateString() }}
        </span>
        <b-button
          v-else-if="props.column.field === 'product_detail'"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-info"
          @click="selectedRowProduct = props.row.products, $bvModal.show('detailModalOrder')"
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50"
          />
          <span class="align-middle">Ürün Detayları</span>
        </b-button>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-input
              v-model="pageLength"
              class="mx-1"
              type="number"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import { VueGoodTable } from 'vue-good-table'
import {
  BPagination, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ProductDetail from './ProductDetail.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormInput,
    BButton,
    ProductDetail,
  },
  directives: { Ripple },
  data() {
    return {
      orders: [],
      selectedRowProduct: [],
      searchTerm: '',
      pageLength: 15,
      columns: [
        {
          label: 'Müşteri İsmi',
          field: 'user.name',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Müşteri Email',
          field: 'user.email',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Müşteri Email',
          field: 'user.phone',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Oluşturma tarihi',
          field: 'created_at',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Ürün Detayları',
          field: 'product_detail',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
      ],
    }
  },
  created() {
    this.getOrders()
  },
  methods: {
    async getOrders() {
      await this.$http.get('invoice/order').then(res => {
        this.orders = res.data.data
      })
    },
  },
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce";
</style>
